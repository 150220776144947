<!--
 * @Author: your name
 * @Date: 2022-04-07 17:11:16
 * @LastEditTime: 2022-04-20 16:00:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifisvn\src\views\bill\index.vue
-->
<template>
  <div class="bill">
    <div class="bill-top">
      <div class="title">账户余额({{info.c_balance?info.c_balance:"0"}}元)</div>
      <div class="money">{{info.c_balance}}</div>
      <div class="lately">最近记录:{{list.length == 0 ?"暂无记录":recordlast}}</div>
    </div>
    <div class="list">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div class="itembox" v-for="(item,index) in list" :key="index">
          <div class="item">
            <div class="left">
              <img src="@/assets/images/bill/meal.png" alt="" class="rechimg" v-if="item.ope_type == 2">
              <img src="@/assets/images/bill/banlac.png" alt="" class="orderimg" v-else>
              <div class="text">
                <p class="pone" v-if="item.ope_type == 1">充值余额</p>
                <p class="pone" v-if="item.ope_type == 2">订购套餐</p>
                <p class="pone" v-if="item.ope_type == 3">余额转移</p>
                <p class="pone" v-if="item.ope_type == 4">退款</p>
                <p>{{item.buy_name}}</p>
              </div>
            </div>
            <div class="right" :class="{bancolor:item.operation ==1}">
            {{item.operation ==1?"+":"-"}}￥{{item.ope_balance}}
            </div> 
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,  // 是否开始加载
      finished: false, // 是否加载完成
      istrue:true,  // 是不是套餐
      page:1,  // 当前第几页
      limit:15,  // 一页几条
      info:{},  // 金额明细信息
      list:[], // 金额明细列表
    }
  },
  computed:{
    // 返回当前最后开通的套餐
    recordlast(){
      if(this.list.length>0){
          return this.list[0].buy_name + "￥" + this.list[0].ope_balance
      }
    }
  },
  methods:{
    onLoad(){
      this.getbalanceLog()
    },
    // 获取账单明细
    getbalanceLog(){
      this.loading = true
      let data ={
        page:this.page,
        iccid:sessionStorage.getItem("iccid"),
        limit:this.limit
      }
      this.balanceLog({data}).then(res =>{
        if(res.data.code == 1){
          this.info = res.data.info
          if(this.page == res.data.pagecount){
            this.list = [...this.list,...res.data.list]
            this.loading = false
            this.finished = true
            this.text = "真的没有了~"
          }else{
            this.loading = false
            this.list = [...this.list,...res.data.list]
            this.loading = false
            this.page++
          }
        }else{
          this.loading = false
          this.finished = true
        }
      })
    }
  },
  mounted(){
    // JS原生设置当前背景颜色
    document.querySelector('body').setAttribute('style', 'background-color:#ffffff')
    this.getbalanceLog()
  }
}
</script>
<style lang="less" scoped>
.bill-top{
  width: 100%;
  height: 186px;
  background-image: url("~@/assets/images/bill/banner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .title{
    color: #cadeff;
    font-size: 13px;
    text-align: center;
    padding-top: 14px;
  }
  .money{
    color: #fff;
    font-size: 60px;
    text-align: center;
    font-family: "bahn";
    margin: 20px 0 10px;
  }
  .lately{
    max-width: 260px;
    height: 26px;
    background-color: rgba(13, 93, 228, 0.8);
    border-radius: 13px;
    margin: 0 auto;
    color: #82aef9;
    text-align: center;
    line-height: 26px;
  }
}
.list{
  width: 100%;
  min-height: 400px;
  background-color: #fff;
  margin-top: -20px;
  border-radius: 20px;
  box-sizing: border-box;
  padding-top: 20px;
  .itembox{
    width: 92%;
    margin: 0 auto;
    padding: 0 4px;
    box-sizing: border-box;
    margin-bottom: 26px;
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left{
        display: flex;
        justify-content: center;
        align-items: center;
        .rechimg{
          height: 20px;
          display: block;
        }
        .orderimg{
          height: 20px;
          display: block;
        }
      }
      .text{
        color: #aab8cf;
        font-size: 12px;
        margin-left: 12px;
        .pone{
          color: #222;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
      .right{
        font: 14px;
        font-family: "bahn";
        font-weight: 600;     
        color: #d53c07;
      }
      .bancolor{
        color: #02ac8a;
      }
    }
  }
}
</style>